<template>
  <div class="" v-if="userIsAdmin">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-2 mt-5">
          <div class="card flex justify-content-center">
            <PanelMenu :model="getNav" class="w-full md:w-25rem" />
          </div>
        </div>
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-12">
              <h1>Список пользователей</h1>
              <div class="btn-box">
                <Button @click="dellUserDialog= true" label="Удалить выбранные" icon="pi pi-user" severity="danger" />
                <Button @click="addUserDialog = true" label="Добавить пользователя" icon="pi pi-user" severity="success" />
              </div>

              <DataTable v-model:selection="selectedDellUser" :value="GET_APP_USERS" dataKey='id'  tableStyle="min-width: 50rem">
                <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                <Column field="id" header="id"></Column>
                <Column field="username" header="Логин"></Column>
                <Column field="is_staff" header="Админ"></Column>
                <Column field="email" header="Имейл"></Column>
              </DataTable>
              <div class="btn-box" style="justify-content: center;">
                <Button @click="createLinkRegistration()" label="Создать ссылку на регистрацию" severity="info" />
              </div>
            </div>
          </div>
        </div>

      </div>
      <Dialog v-model:visible="addUserDialog" modal header="Выберете пользователя" :style="{ width: '25rem' }">
        <div class="justify-center gap-4 mb-8">
          <MultiSelect style="width:100%;" v-model="selctedAddUser" :options="userIfNotAssign" optionLabel="username" placeholder="Выберете пользователя"
                       :maxSelectedLabels="5" class="w-full md:w-80">
          </MultiSelect>
        </div>
        <div class="justify-center ">
          <Button type="button" style="width:100%; margin-top: 10px;" label="Добавить" @click="addUserInApp()"></Button>
        </div>
      </Dialog>

      <Dialog v-model:visible="dellUserDialog" modal header="Удалить выбранных" :style="{ width: '25rem' }">
        <div class="justify-center ">
          <Button @click="delUserInApp()" type="button" severity="danger" style="width:100%; margin-top: 10px;" label="Удалить"></Button>
        </div>
      </Dialog>

    </div>
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import PanelMenu from 'primevue/panelmenu';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import router from "@/router";
import MultiSelect from 'primevue/multiselect';
export default {
  name:'',
  components: {
    PanelMenu,
    Button,
    Checkbox,
    Dialog,
    DataTable,
    Column,
    MultiSelect
  },
  data(){
    return{
      selectedDellUser:[],
      selctedAddUser:[],
      metaKey: true,
      appID:this.$route.params.id,
      addUserDialog:false,
      dellUserDialog:false,
    }
  },
  mounted(){
    this.saveAppID(this.$route.params.id)
    this.setIdApp(this.$route.params.id)
    this.GET_ALL_USER()
    this.ApplicationUserDetail(this.$route.params.id)
    this.getNeedUserApp(this.$route.params.id)
  },
  created(){

  },
  methods:{
    createLinkRegistration(){
      let link = `https://${window.location.hostname}/registration/${this.$route.params.id}/`
      navigator.clipboard.writeText(link)
          .then(function() {
            alert('Текст скопирован в буфер обмена');
          })
          .catch(function(err) {
            console.error('Ошибка при копировании в буфер обмена', err);
          });
    },
    addUserInApp(){


      let AppId = this.$route.params.id
      let data = this.GET_NEED_USERS_APP
      console.log('fdsfsdfds',this.GET_DATA_ALL_USERS)
      console.log(this.GET_NEED_USERS_APP)
      if(this.selctedAddUser.length > 0){

        this.selctedAddUser.forEach(user => {data.assign.push(user.id)})

        this.updateUserInApp({data, AppId})
        this.selctedAddUser = []
      } else {

      }
    },
    delUserInApp(){
      let AppId = this.$route.params.id
      let data = this.GET_NEED_USERS_APP

      if(this.selectedDellUser.length > 0){
        data.assign = data.assign.filter(app => {
          return !this.selectedDellUser.some(user => user.id === app);
        });

        this.updateUserInApp({data, AppId})
        this.dellUserDialog = false
        this.selectedDellUser = []
      } else {

      }

    },
    ...mapMutations({
      updateUserInApp:'updateUserInApp',
      saveAppID:'saveAppID',
      setIdApp:'setIdApp',
    }),
    ...mapActions({
      GET_ALL_USER:'GET_ALL_USER',
      ApplicationUserDetail:'ApplicationUserDetail',
      getNeedUserApp:'getNeedUserApp',
    }),
  },
  computed:{
    userIfNotAssign() {
      if(this.GET_NEED_USERS_APP?.assign?.length > 0){
        return this.GET_DATA_ALL_USERS.filter(app => {
          return !this.GET_NEED_USERS_APP?.assign.some(user => user === app.id);
        });
      } else {
        return this.GET_DATA_ALL_USERS
      }

    },
    ...mapGetters([
      'getNav',
      'userIsAdmin',
      'GET_APP_USERS',
      'GET_NEED_USERS_APP',
      'GET_DATA_ALL_USERS',
    ]),
  }
};
</script>

<style scoped>
.btn-box {
  display:flex;
  justify-content: space-between;
  margin-bottom: 15px;
  background: #f8f9fa;
  color: #343a40;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
}
.justify-center {
  justify-content: center !important;
  display:flex;
  margin-top: 5px;
  width:100%;
}
</style>

